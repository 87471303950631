import { TFunction } from "i18next";

import { LinkType } from "@tager/admin-ui";
import { getPageListUrl } from "@tager/admin-pages";
import { getBlogCategoryListUrl } from "@tager/admin-blog";
import { getAdminListUrl, getRoleListUrl } from "@tager/admin-administrators";
import {
  getBannersBannerListUrl,
  getBannersZoneListUrl,
} from "@tager/admin-banners";
import { getFilesListUrl } from "@tager/admin-files";
import {
  getCommandsListUrl,
  getCronLogsUrl,
  getCommandsLogsUrl,
} from "@tager/admin-cron";
import { getSeoTemplatesUrl } from "@tager/admin-seo";

import { getBlogPostListUrl } from "@/utils/paths";
import { getVideosListUrl } from "@/modules/videos";

type LinkKey =
  | "HOME"
  | "SEO_TEMPLATES"
  | "BANNERS_LIST"
  | "BANNERS_ZONES_LIST"
  | "PAGE_LIST"
  | "BLOG_CATEGORY_LIST"
  | "BLOG_POST_LIST"
  | "BLOG_POST_REVIEWS_LIST"
  | "BLOG_POST_OFFERS_LIST"
  | "BLOG_POST_NEWS_LIST"
  | "BLOG_POST_LESSONS_LIST"
  | "FILES_LIST"
  | "VIDEOS_LIST"
  | "ADMIN_ROLES"
  | "ADMIN_ADMINS"
  | "CRON_COMMANDS"
  | "CRON_LOGS"
  | "CRON_COMMANDS_LOGS";

export const getLinks = (): Readonly<Record<LinkKey, LinkType>> => ({
  HOME: {
    url: "/",
    text: "Главная",
  },
  BANNERS_LIST: {
    url: getBannersBannerListUrl(),
    text: "Баннеры",
  },
  BANNERS_ZONES_LIST: {
    url: getBannersZoneListUrl(),
    text: "Баннерные зоны",
  },
  FILES_LIST: {
    url: getFilesListUrl(),
    text: "Файлы",
  },
  VIDEOS_LIST: {
    url: getVideosListUrl(),
    text: "Видеобзоры",
  },
  BLOG_CATEGORY_LIST: {
    url: getBlogCategoryListUrl(),
    text: "Категории",
  },
  BLOG_POST_LIST: {
    url: getBlogPostListUrl(),
    text: "Посты",
  },
  BLOG_POST_NEWS_LIST: {
    url: getBlogPostListUrl(1),
    text: "Новости",
  },
  BLOG_POST_OFFERS_LIST: {
    url: getBlogPostListUrl(2),
    text: "Акции",
  },
  BLOG_POST_REVIEWS_LIST: {
    url: getBlogPostListUrl(3),
    text: "Обзоры",
  },
  BLOG_POST_LESSONS_LIST: {
    url: getBlogPostListUrl(4),
    text: "Учебник",
  },
  PAGE_LIST: {
    url: getPageListUrl(),
    text: "Страницы",
  },
  ADMIN_ROLES: {
    url: getRoleListUrl(),
    text: "Роли",
  },
  ADMIN_ADMINS: {
    url: getAdminListUrl(),
    text: "Администраторы",
  },
  CRON_COMMANDS: {
    url: getCommandsListUrl(),
    text: "Команды",
  },
  CRON_COMMANDS_LOGS: {
    url: getCommandsLogsUrl(),
    text: "Команды - Логи",
  },
  CRON_LOGS: {
    url: getCronLogsUrl(),
    text: "Cron Логи",
  },
  SEO_TEMPLATES: {
    url: getSeoTemplatesUrl(),
    text: "SEO Шаблоны",
  },
});
