import { type RouteRecordRaw, createWebHistory } from "vue-router";

import { createRouter } from "@tager/admin-layout";
import { PAGE_FORM_ROUTE, PAGE_LIST_ROUTE } from "@tager/admin-pages";
import { SEO_TEMPLATES_ROUTE } from "@tager/admin-seo";
import {
  BLOG_CATEGORY_LIST_ROUTE,
  BLOG_CATEGORY_FORM_ROUTE,
  BLOG_POST_FORM_ROUTE,
  BLOG_POST_LIST_ROUTE,
} from "@tager/admin-blog";
import { FILES_FORM_ROUTE, FILES_LIST_ROUTE } from "@tager/admin-files";
import {
  PAGE_COMMANDS_ROUTE,
  PAGE_CRON_LOGS_ROUTE,
  PAGE_COMMANDS_LOGS_ROUTE,
  PAGE_COMMANDS_LOG_DETAILS_ROUTE,
  PAGE_CRON_LOG_DETAILS_ROUTE,
  PAGE_COMMANDS_DETAILS_ROUTE,
} from "@tager/admin-cron";
import {
  ADMIN_FORM_ROUTE,
  ADMIN_LIST_ROUTE,
  ROLE_FORM_ROUTE,
  ROLE_LIST_ROUTE,
} from "@tager/admin-administrators";
import {
  BANNERS_BANNER_FORM_ROUTE,
  BANNERS_BANNER_LIST_ROUTE,
  BANNERS_ZONE_LIST_ROUTE,
} from "@tager/admin-banners";

import Home from "@/views/Home.vue";
import { VIDEOS_FORM_ROUTE, VIDEOS_LIST_ROUTE } from "@/modules/videos";

export const HOME_ROUTE: RouteRecordRaw = {
  path: "/",
  component: Home,
  name: "Home",
  meta: {
    getBreadcrumbs: (route, { t }) => [{ url: "/", text: t("admin:home") }],
  },
};

export const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH || "/"),
  routes: [
    HOME_ROUTE,
    BLOG_CATEGORY_LIST_ROUTE,
    BLOG_CATEGORY_FORM_ROUTE,
    BLOG_POST_FORM_ROUTE,
    BLOG_POST_LIST_ROUTE,
    PAGE_FORM_ROUTE,
    PAGE_LIST_ROUTE,
    ADMIN_FORM_ROUTE,
    ADMIN_LIST_ROUTE,
    ROLE_FORM_ROUTE,
    ROLE_LIST_ROUTE,
    FILES_LIST_ROUTE,
    FILES_FORM_ROUTE,

    BANNERS_ZONE_LIST_ROUTE,
    BANNERS_BANNER_LIST_ROUTE,
    BANNERS_BANNER_FORM_ROUTE,

    PAGE_COMMANDS_ROUTE,
    PAGE_CRON_LOGS_ROUTE,
    PAGE_COMMANDS_LOGS_ROUTE,
    PAGE_COMMANDS_LOG_DETAILS_ROUTE,
    PAGE_CRON_LOG_DETAILS_ROUTE,
    PAGE_COMMANDS_DETAILS_ROUTE,

    VIDEOS_LIST_ROUTE,
    VIDEOS_FORM_ROUTE,
    SEO_TEMPLATES_ROUTE,
  ],
});
